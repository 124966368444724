import Link from 'next/link';
import { LogoEmpresasB } from '../../../icons';
import { LogoBuydepa } from '../../../../../assets';
import { ChileanFlag } from '../../../icons';
import { ColombianFlag } from '../../../icons';
import SocialMediaNav from '../../SocialNavs/SocialMediaNav';

export default function Footer() {
  const footerNavigation = {
    solutions: [{ name: 'Sobre Buydepa', href: '/quienes-somos/nosotros' }],
    support: [
      { name: 'Preguntas frecuentes', href: '/preguntas-frecuentes/faqs' },
      {
        name: 'Términos y condiciones',
        href: '/terminos-y-condiciones-de-uso',
      },
      {
        name: 'Postventa',
        href: '/postventa',
      },
      {
        name: 'Política de tratamiento de datos',
        href: '/proteccion-de-datos-y-privacidad',
      },
    ],
    company: [
      { name: 'App Brokers', href: '/brokers' },
      { name: 'Créditos', href: '/credito' },
      { name: 'Subsidios', href: '/subsidios' },
      { name: 'Blog', href: '/blog' },
    ],
  };

  const footerDirections = {
    countries: [
      {
        name: 'Chile',
        flag: <ChileanFlag />,
        direction: `Rosario Norte 615 Oficina 1303,
      Las Condes, Santiago. `,
        email: 'Email: contacto@buydepa.com',
      },
    ],
  };

  return (
    <footer
      className="bg-footer-texture bg-no-repeat bg-right-bottom bg-buydepa-blue-light print:hidden"
      aria-labelledby="footer-heading"
    >
      <div className="bg-primary">
        <div className="mx-auto max-w-7xl py-6 grid grid-cols-1 md:grid-cols-3 justify-items-center md:px-8 md:justify-items-stretch items-center">
          <div className=" justify-items-center md:justify-items-start">
            <Link href={'/'}>
              <LogoBuydepa width="200" height="40" color="#19EC93" />
            </Link>
          </div>
          <div className="pt-6 md:pt-0 justify-items-center">
            <SocialMediaNav color="#19EC93" />
          </div>
        </div>
      </div>
      <div className="px-8 mx-auto max-w-7xl">
        <div className="pt-12">
          <div className="grid grid-cols-2 gap-12 md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-6">
            <div className="col-span-2 pr-4">
              <ul>
                {footerDirections.countries.map((item) => (
                  <li key={item.name} className="flex flex-row mb-12">
                    <div className="mr-6 mt-2">{item.flag} </div>
                    <div className="flex flex-col text-slate-900 text-opacity-60 text-base font-normal mb-4">
                      <Link
                        href={`https://maps.google.com/maps?q=${item.direction}`}
                        target="_blank"
                      >
                        {item.direction}
                      </Link>
                      <span>{item.email}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-base font-bold tracking-wider text-buydepa-primary uppercase">
                Somos Buydepa
              </h3>
              <ul className="mt-4 space-y-4">
                {footerNavigation.solutions.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="text-slate-900 text-opacity-60 text-base font-normal  underline"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-base font-bold tracking-wider text-buydepa-primary uppercase">
                Info
              </h3>
              <ul className="mt-4 space-y-4">
                {footerNavigation.support.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="text-slate-900 text-opacity-60 text-base font-normal  underline"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-base font-bold tracking-wider text-buydepa-primary uppercase">
                Otros
              </h3>
              <ul className="mt-4 space-y-4">
                {footerNavigation.company.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="text-slate-900 text-opacity-60 text-base font-normal  underline"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-2  md:col-span-1 text-center flex flex-row  items-center  md:flex-col md:mt-0 mt-12">
              <div className="mx-10">
                <LogoEmpresasB className="inline-flex" />
              </div>
              <div className=" md:mt-5 text-left md:text-center text-sm opacity-70 text-slate-900">
                El impacto social es parte de nuestro ADN y modelo de negocio.
              </div>
            </div>
          </div>
          <div className="py-12 mt-12 border-t border-gray-300">
            <p className="text-sm text-primary-600 text-center items-center">
              Buydepa 2023 - Todos los derechos reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
